declare global {
  type OperationResult<T> = {
    data?: T;
    errorMessage: string;
    errorCode: number;
  };

  type Admin = {
    UserAttributes: Array<{ Name: string, Value: string }>,
    Enabled: boolean,
    UserCreateDate: string,
    UserLastModifiedDate: string,
    UserStatus: string,
    Username: string
  }

  type ApiResponse = {
    message: string
  }

  type ErrorResponse = {
    type: string,
    errors: string[];
  }

  type Company = {
    company_id: number;
    company_name: string;
    internal_name: string;
    company_type_id: number;
    company_type_name?: string;
    company_address_1: string;
    company_address_2: string;
    company_city: string;
    company_state: string;
    company_zip?: string;
    created_at?: Date;
    payment_info_id?: number;
    parent_company_id?: number;
    has_assessments: boolean,
    is_billing: boolean,
    industry_id: number,
    industry_name: string
  }

  type PaymentInfo = {
    payment_info_id: number,
    stripe_subscription_id: string,
    stripe_subscription_status: string,
    stripe_customer_id: string,
    stripe_price_id: string
  }

  type PointOfContact = {
    poc_id: number,
    poc_type_id: number,
    poc_name: string,
    poc_phone_number: string,
    poc_email: string,
    poc_address_1: string,
    poc_address_2: string,
    poc_city: string,
    poc_state: string,
    poc_zip: string,
    notes: string,
    company_id: number
  }

  type PaymentInfoDTO = {
    stripe_subscription_id?: string | null | undefined;
    stripe_subscription_status?: string | null | undefined;
    stripe_customer_id?: string | null | undefined;
    stripe_price_id?: string | null | undefined;
  }
}

export interface Response {
  questionId: number;
  optionChoiceId: number;
}

export interface ResponseData {
  responses: Response[];
}

export interface OptionChoice {
  option_choice_id: number;
  option_choice_name: string;
  option_group_id: number;
}

export interface Survey {
  survey_id: number;
  company_id: number;
  company_name: string;
  survey_name: string;
  start_date: string;
  end_date: string;
  status_id: number;
  status_name: string;
  si_value: string | null;
  at_risk_quotient: number | null;
  mental_health_value: string | null;
  sleep_value: string | null;
  diet_value: string | null;
  exercise_value: string | null;
  hydration_value: string | null;
  red_count: number | null;
  yellow_count: number | null;
  total_recipients: number;
  green_count: number | null;
  disengaged_count: number | null;
  response_rate: string | null;
  created_at: Date;
}

export interface SurveyKey {
  is_taken: Boolean;
  expiration_date: string;
  email_send_date?: string;
  survey_key: string;
  survey_key_id?: number;
  survey_id: number;
  user_id: number;
}

export interface Question {
  created_at: string;
  input_type_id: number;
  option_group_id: number;
  question_id: number;
  question_sentiment_id: number;
  question_title: string;
  section_name: string;
  survey_id: number;
  section_id: number;
  updated_at: string;
}

export interface User {
  job_title_id: number;
  age_id: number;
  years_experience_id: number;
  gender_id: number;
}

export interface Admin2 {
  email: string | null;
}

export interface Respondent {
  age_id: number;
  company_id: number;
  company_job_title_id: number;
  company_job_title_name?: string;
  group_ids: number[];
  created_at: string;
  diet_value: string;
  end_date?: string;
  exercise_value: string;
  gender_id: number;
  hydration_value: string;
  respondent_id: number;
  mental_health_value: string;
  sleep_value: string;
  start_date?: string;
  status_id?: number;
  strata_job_title_id?: number;
  strata_job_title_name?: string;
  survey_id: number;
  survey_name?: string;
  years_experience_id: number;
}

export interface StrataJobTitle {
  strata_job_title_id: number,
  strata_job_title_name: string
}

export interface SurveyEvent {
  name: string,
  groupName: string,
  state: string,
  fireDate: string
}

export interface Survey2 {
  company_id: number;
  created_at: string;
  diet_value: string;
  end_date: string;
  exercise_value: string;
  hydration_value: string;
  si_value: string;
  at_risk_quotient: number;
  sleep_value: string;
  start_date: string;
  status_id: number;
  status_name: string;
  survey_id: number;
  survey_name: string;
}

export interface Group {
  group_id: number,
  group_name: string,
  company_id: number
}

export interface Activity {
  activity_id: number,
  activity_type_id: number,
  activity_type_name: string,
  message_template: string,
  cogntio_username: string,
  email: string,
  first_name: string,
  last_name: string,
  company_id: number,
  created_at: string
}

export type TrendChartData = {
  value: number;
  date: Date;
};

export type Dataset = {
  label: string;
  data: TrendChartData[];
  color?: string;
};

// copy of type from @aws-sdk/client-cognito-identity-provider
export interface UserType {
  Username?: string;
  Attributes?: {
    Name: string | undefined;
    Value?: string;
  }[];
  UserCreateDate?: Date;
  UserLastModifiedDate?: Date;
  Enabled?: boolean;
  UserStatus?:
    "ARCHIVED" |
    "COMPROMISED" |
    "CONFIRMED" |
    "EXTERNAL_PROVIDER" |
    "FORCE_CHANGE_PASSWORD" |
    "RESET_REQUIRED" |
    "UNCONFIRMED" |
    "UNKNOWN"
  MFAOptions?: {
    DeliveryMedium?: "EMAIL" | "SMS"
    AttributeName?: string;
  }[];
}

export enum Role {
  Viewer = 1,
  Manager = 2
}