import { ClipboardDocumentListIcon, EnvelopeIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

const actions = [
  {
    name: "Send Email",
    description: "Send emails to leadership teams",
    icon: EnvelopeIcon,
    href: "/home/send-email",
  },
  {
    name: "Send Demo Assessment",
    description: "Send a one-off assessment",
    icon: ClipboardDocumentListIcon,
    href: "/home/demo-assessment",
  },
];

export default function ActionButtons() {
  return (
    <div className="grid grid-cols-1 gap-4 sm:grid-cols-4">
      {actions.map((action, index) => (
        <div
          key={index}
          className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-3 py-3 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400"
        >
          <action.icon className="w-8 h-8 text-gray-600" />
          <div className="min-w-0 flex-1">
            <Link to={action.href} className="focus:outline-none">
              <span aria-hidden="true" className="absolute inset-0" />
              <p className="text-sm font-medium text-gray-900">{action.name}</p>
              <p className="truncate text-sm text-gray-500">{action.description}</p>
            </Link>
          </div>
        </div>
      ))}
    </div>
  );
}
