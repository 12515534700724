import { Fragment, useContext, useRef, useState } from "react";
import { Dialog, Switch, Transition } from "@headlessui/react";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import Button from "./Button";
import { AuthContext } from "../context/authContext";
import { useNavigate } from "react-router";
import Tooltip from "./Tooltip";
import useApi from "../hooks/useApi";

export default function AdminModal(props: { open: boolean; setOpen: (open: boolean) => void; details?: Admin; companyId?: number }) {
  const { open, setOpen, details, companyId } = props;
  const cancelButtonRef = useRef(null);
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const { deleteApiData } = useApi();

  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [newUsername, setNewUsername] = useState<string>();
  const [isStrataAdmin, setIsStrataAdmin] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  const statusExplanations = {
    UNCONFIRMED: "User has been created but not confirmed.",
    CONFIRMED: "User has been confirmed.",
    UNKNOWN: "User status isn't known.",
    RESET_REQUIRED: "User is confirmed, but the user must request a code and reset their password before they can sign in.",
    FORCE_CHANGE_PASSWORD:
      "The user is confirmed and the user can sign in using a temporary password, but on first sign-in, the user must change their  password to a new value before doing anything else.",
  } as any;

  function getValueByName(name: string, attributes: Array<{ Name: string; Value: string }>): string | undefined {
    const pair = attributes.find((pair) => pair.Name === name);
    return pair ? pair.Value : undefined;
  }

  async function createAdminUser(): Promise<void> {
    if (!auth.sessionInfo?.idToken) {
      navigate("/login");
      return;
    }
    if (
      !newUsername ||
      !newUsername.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
    ) {
      setError("Must be a valid email");
      return;
    }
    try {
      console.log(companyId);

      setSubmitting(true);
      const response = await fetch(`${process.env.REACT_APP_API_URL}/strata/companies/create-admin-user`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: auth.sessionInfo.idToken,
        },
        body: JSON.stringify({
          email: newUsername,
          companyId,
          isStrataAdmin,
        }),
      });
      const body = await response.json();
      if (response.status !== 200) {
        if (body.name === "TokenExpiredError") {
          alert("Token expired, please try again");
          window.location.reload();
          return;
        }
        if (body.name === "UsernameExistsException") {
          alert("An admin already exists with that email");
          setSubmitting(false);
          return;
        }
        console.error(body);
        alert("An error occured creating new admin");
        setSubmitting(false);
        return;
      }
      alert("Admin user created successfully");
      window.location.reload();
    } catch (err) {
      console.error(err);
    }
  }

  async function deleteAdmin() {
    try {
      if (details) {
        setSubmitting(true);

        const { response, body } = await deleteApiData(`/strata/companies/admin/delete?username=${encodeURIComponent(details.Username)}&companyId=${companyId}`);

        if (response.status === 200) {
          alert("Admin deleted successfully");
          setSubmitting(false);
          window.location.reload();
        } else {
          console.error(response);
          console.error(body);
          alert("Error deleting admin");
          setSubmitting(false);
        }
      }
    } catch (err) {
      console.error(err);
    }
  }

  async function resendTempPassword() {
    try {
      if (auth.sessionInfo?.idToken && details) {
        setSubmitting(true);
        await fetch(`${process.env.REACT_APP_API_URL}/strata/companies/adminUserResetTempPassword`, {
          method: "Post",
          headers: {
            "Content-Type": "application/json",
            Authorization: auth.sessionInfo.idToken,
          },
          body: JSON.stringify({
            username: getValueByName("email", details.UserAttributes),
          }),
        })
          .then((res) => {
            if (res.status !== 200) {
              console.error(res);
              alert("Error sending temporary password");
              return;
            }
            return res.json();
          })
          .then((data) => {
            if (data) {
              alert("New Temporary Password Sent");
            }
            setSubmitting(false);
          });
      } else {
        navigate("/login");
      }
    } catch (err) {
      console.error(err);
    }
  }

  // Existing admin details
  if (details?.Username) {
    return (
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={() => {
            setOpen(false);
            setConfirmDelete(false);
          }}
        >
          <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6">
                  <div>
                    <div className="">
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        Details
                      </Dialog.Title>
                      <div>
                        <div className="mt-6">
                          <dl className="grid grid-cols-1 sm:grid-cols-12">
                            <div className="border-t border-gray-100 px-4 py-6 sm:col-span-3 sm:px-0">
                              <dt className="text-sm font-medium leading-6 text-gray-900">Name</dt>
                              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                                {getValueByName("given_name", details.UserAttributes)} {getValueByName("family_name", details.UserAttributes)}
                              </dd>
                            </div>
                            <div className="border-t border-gray-100 px-4 py-6 sm:col-span-3 sm:px-0">
                              <dt className="text-sm font-medium leading-6 text-gray-900">User Status</dt>
                              <dd className="flex mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                                <div className="mr-1">{details.UserStatus}</div><Tooltip message={statusExplanations[details.UserStatus]} />
                              </dd>
                              {details.UserStatus === "FORCE_CHANGE_PASSWORD" && (
                                <Button text="Send New Temporary Password" size="sm" variant="warning" onClick={() => resendTempPassword()} submitting={submitting} />
                              )}
                            </div>
                            <div className="border-t border-gray-100 px-4 py-6 sm:col-span-6 sm:px-0">
                              <dt className="text-sm font-medium leading-6 text-gray-900">Username</dt>
                              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{details.Username}</dd>
                            </div>
                            <div className="border-t border-gray-100 px-4 py-6 sm:col-span-6 sm:px-0">
                              <dt className="text-sm font-medium leading-6 text-gray-900">Email</dt>
                              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{getValueByName("email", details.UserAttributes)}</dd>
                            </div>
                            <div className="border-t border-gray-100 px-4 py-6 sm:col-span-6 sm:px-0">
                              <dt className="text-sm font-medium leading-6 text-gray-900">Phone Number</dt>
                              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{getValueByName("phone_number", details.UserAttributes)}</dd>
                            </div>
                            <div className="border-t border-gray-100 px-4 py-6 sm:col-span-6 sm:px-0">
                              <dt className="text-sm font-medium leading-6 text-gray-900">Last Modified Date</dt>
                              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                                {new Date(details.UserLastModifiedDate).toLocaleDateString()} {new Date(details.UserLastModifiedDate).toLocaleTimeString()}
                              </dd>
                            </div>
                            <div className="border-t border-gray-100 px-4 py-6 sm:col-span-6 sm:px-0">
                              <dt className="text-sm font-medium leading-6 text-gray-900">User Created Date</dt>
                              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                                {new Date(details.UserCreateDate).toLocaleDateString()} {new Date(details.UserCreateDate).toLocaleTimeString()}
                              </dd>
                            </div>
                          </dl>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <Button
                      text={confirmDelete ? "Confirm Delete?" : "Delete"}
                      variant="danger"
                      className="sm:col-start-2"
                      onClick={() => (confirmDelete ? deleteAdmin() : setConfirmDelete(true))}
                      submitting={submitting}
                    />
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                      onClick={() => {
                        setOpen(false);
                        setConfirmDelete(false);
                      }}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  } else {
    //Create new admin
    return (
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
          <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div>
                    <div className="">
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        Create New Admin
                      </Dialog.Title>
                      <div className="mt-5">
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                          Email
                        </label>
                        <input
                          className="w-full mr-3 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          type="text"
                          onChange={(e) => setNewUsername(e.target.value)}
                          placeholder="Email"
                        />
                        <p className="text-red-500 text-sm">{error}</p>
                        <div className="mt-3 flex items-center">
                          <label htmlFor="isStrataAdmin" className="text-sm">
                            <div className="font-medium text-gray-900">Is Strata Admin</div>
                            <div className="text-xs text-gray-500">Will not show on customer dashboard admin list</div>
                          </label>
                          <Switch
                            id="isStrataAdmin"
                            checked={isStrataAdmin}
                            onChange={setIsStrataAdmin}
                            className={`group ml-5 relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent ${
                              isStrataAdmin ? "bg-blue-600" : "bg-gray-200"
                            } transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2`}
                          >
                            <span
                              aria-hidden="true"
                              className={`pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out ${
                                isStrataAdmin ? "translate-x-5" : ""
                              }`}
                            />
                          </Switch>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-4 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <Button text="Cancel" variant="secondary" onClick={() => setOpen(false)} />
                    <Button text="Create Admin" type="button" onClick={() => createAdminUser()} submitting={submitting} />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  }
}
