import React, { useReducer } from "react";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  Navigate,
} from "react-router-dom";

import AuthProvider, {
  AuthIsSignedIn,
  AuthIsNotSignedIn,
} from "./context/authContext";

import Login from "./pages/authPages/Login";
import Registration from "./pages/authPages/Registration";
import ForgotPassword from "./pages/authPages/ForgotPassword";
import CreateCompany from "./pages/companies/CreateCompany";
import CompanyDetails from "./pages/companies/CompanyDetails";
import Nav from "./pages/NavBar";
import Home from "./pages/home/Home"
import CreateAssessment from "./pages/assessments/CreateAssessment"
import AssessmentDetails from "./pages/assessments/AssessmentDetails"
import Assessments from "./pages/assessments/Assessments"
import SignOut from "./pages/authPages/SignOut"
import Companies from "./pages/companies/Companies";
import Stats from "./pages/stats/Stats";
import SendEmail from "./pages/home/SendEmail";
import DemoAssessment from "./pages/home/DemoAssessment";

export interface IContextProps {}

const App: React.FC<IContextProps> = (props) => {
  return (
    <Router>
      <AuthProvider>
        {/* User is signed in */}
        <AuthIsSignedIn>
          <Routes>
            <Route path="" element={<Nav />}>
              <Route path="companies" element={<Companies />} />
              <Route path="stats" element={<Stats />} />
              <Route path="assessments/create" element={<CreateAssessment />} />
              <Route path="assessment/:id" element={<AssessmentDetails />} />
              <Route path="assessments" element={<Assessments />} />
              <Route path="company/create" element={<CreateCompany />} />
              <Route path="company/:companyId" element={<CompanyDetails />} />
              <Route path="company/create" element={<CreateCompany />} />
              <Route path="home/send-email" element={<SendEmail />} />
              <Route path="home/demo-assessment" element={<DemoAssessment />} />
              <Route path="home" element={<Home />} />
              <Route path="sign-out" element={<SignOut />} />
              <Route path="" element={<Navigate to={"home"} />} />
              <Route path="/*" element={<Navigate to={"/home"} />} />
            </Route>
            <Route path="/login" element={<Navigate to={"/home"} />} />
          </Routes>
        </AuthIsSignedIn>

        {/* User is not signed in */}
        <AuthIsNotSignedIn>
          <Routes>
            <Route path="/" element={<Navigate to={"/login"} />} />
            <Route path="/*" element={<Navigate to={"/login"} />} />
            <Route path="/login" element={<Login />} />
            <Route path="/registration" element={<Registration />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
          </Routes>
        </AuthIsNotSignedIn>
      </AuthProvider>
    </Router>
  );
};

export default App;
