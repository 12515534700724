import { ListBulletIcon, HomeIcon, ClockIcon, UserIcon, UserGroupIcon, ChartBarIcon, ClipboardDocumentListIcon, PaperAirplaneIcon, ArrowTrendingUpIcon } from "@heroicons/react/24/outline";
import { useQuery } from "@tanstack/react-query";
import useApi from "../../hooks/useApi";
import { Survey } from "../../types";

export default function Stats() {
  const { getApiData } = useApi();

  const { data: companies } = useQuery<Company[]>({
    queryKey: ["companies"],
    queryFn: () => getApiData(`/strata/companies`),
    initialData: [],
  });

  const { data: surveys } = useQuery<Survey[]>({
    queryKey: ["surveys"],
    queryFn: () => getApiData(`/strata/surveys`),
    initialData: [],
  });

  const { data: surveyKeyCount } = useQuery<number>({
    queryKey: ["surveyKeyCount"],
    queryFn: () => getApiData(`/strata/stats/survey-key-count`),
    initialData: 0,
  });

  const { data: userCount } = useQuery<number>({
    queryKey: ["userCount"],
    queryFn: () => getApiData(`/strata/stats/user-count`),
    initialData: 0,
  });

  const { data: adminCount } = useQuery<number>({
    queryKey: ["adminCount"],
    queryFn: () => getApiData(`/strata/stats/admin-count`),
    initialData: 0,
  });

  const { data: respondentCount } = useQuery<number>({
    queryKey: ["respondentCount"],
    queryFn: () => getApiData(`/strata/stats/respondent-count`),
    initialData: 0,
  });

  const { data: responseCount } = useQuery<number>({
    queryKey: ["responseCount"],
    queryFn: () => getApiData(`/strata/stats/response-count`),
    initialData: 0,
  });

  const { data: genderCounts } = useQuery<{ maleCount: number; femaleCount: number }>({
    queryKey: ["genderCounts"],
    queryFn: () => getApiData(`/strata/stats/gender-count`),
  });

  const malePercentage = genderCounts?.maleCount && respondentCount ? ((genderCounts.maleCount / respondentCount) * 100).toFixed(0) : 0;
  const femalePercentage = genderCounts?.femaleCount && respondentCount ? ((genderCounts.femaleCount / respondentCount) * 100).toFixed(0) : 0;

  const { data: avgResponseTime } = useQuery<{ minutes: number; seconds: number; milliseconds: number }>({
    queryKey: ["avgResponseTime"],
    queryFn: () => getApiData(`/strata/stats/avg-response-time`),
  });

  const companiesWithAssessments = companies.filter((c) => c.has_assessments === true);

  const stats = [
    { name: "Total Accounts Running Assessments", stat: companiesWithAssessments.length?.toLocaleString(), icon: HomeIcon, columns: 4 },
    { name: "Total Leaders", stat: adminCount?.toLocaleString(), icon: UserIcon, columns: 4 },
    { name: "Total Participants", stat: userCount?.toLocaleString(), icon: UserGroupIcon, columns: 4 },
    { name: "Total Assessments", stat: surveys.length?.toLocaleString(), icon: ChartBarIcon, columns: 3 },
    { name: "Total Assessment Invites", stat: surveyKeyCount?.toLocaleString(), icon: PaperAirplaneIcon, columns: 3 },
    { name: "Total Respondents", stat: respondentCount?.toLocaleString(), icon: ClipboardDocumentListIcon, columns: 3 },
    { name: "Overall Response Rate", stat: `${((respondentCount / surveyKeyCount) * 100).toFixed(1)}%`, icon: ArrowTrendingUpIcon, columns: 3 },
    { name: "Total Questions Answered", stat: responseCount?.toLocaleString(), icon: ListBulletIcon, columns: 4 },
    { name: "Average Response Time", stat: `${avgResponseTime?.minutes ?? 0} minutes, ${avgResponseTime?.seconds ?? 0} seconds`, icon: ClockIcon, columns: 4 },
    {
      name: "Gender Distribution",
      stat: `${malePercentage}% male, ${femalePercentage}% female`,
      icon: () => (
        <svg height="25px" width="25px" viewBox="0 0 27.809 27.809" fill="#FFFFFF">
          <path d="M23.76,0.106c-0.067-0.075-0.178-0.116-0.276-0.104l-4.367,0.516 c-0.116,0.016-0.217,0.095-0.258,0.204c-0.045,0.109-0.02,0.241,0.07,0.342l0.891,0.888c0.053,0.05,0.123,0.075,0.197,0.067 l0.699-0.082l-3.395,3.394c-0.99-0.723-2.156-1.104-3.385-1.104c-3.176,0-5.76,2.577-5.77,5.75 c-2.483,0.697-4.202,2.95-4.202,5.544c0,2.845,2.075,5.255,4.863,5.692v2.155H6.433c-0.133,0-0.24,0.105-0.24,0.236v1.333 c0,0.132,0.107,0.239,0.24,0.239h2.395v2.396c0,0.133,0.105,0.237,0.238,0.237h1.335c0.13,0,0.237-0.104,0.237-0.237v-2.396h2.396 c0.132,0,0.239-0.107,0.239-0.239v-1.333c0-0.131-0.107-0.236-0.239-0.236h-2.396v-2.155c2.782-0.438,4.854-2.838,4.863-5.676 c2.486-0.697,4.204-2.951,4.204-5.546c0-1.253-0.394-2.438-1.138-3.436l3.323-3.326l-0.067,0.593 c-0.008,0.071,0.018,0.145,0.067,0.195l0.89,0.893c0.062,0.061,0.143,0.093,0.227,0.093c0.034,0,0.068-0.006,0.104-0.019 c0.119-0.042,0.201-0.145,0.215-0.268l0.518-4.364C23.851,0.266,23.821,0.175,23.76,0.106z M9.734,19.928 c-2.429,0-4.405-1.978-4.405-4.406c0-1.881,1.224-3.558,3.006-4.155c0.626,2.562,2.94,4.396,5.603,4.396 c0.064,0,0.131-0.004,0.196-0.006C14.012,18.077,12.084,19.928,9.734,19.928z M9.678,11.118c0.02-0.001,0.038-0.001,0.056-0.001 c2.002,0,3.751,1.362,4.257,3.276c-0.019,0.002-0.037,0.003-0.054,0.003C11.934,14.397,10.184,13.033,9.678,11.118z M15.334,14.149 c-0.623-2.562-2.938-4.396-5.601-4.396c-0.067,0-0.132,0.002-0.197,0.005c0.122-2.319,2.05-4.172,4.4-4.172 c2.428,0,4.407,1.978,4.407,4.407C18.344,11.874,17.116,13.55,15.334,14.149z"></path>
        </svg>
      ),
      columns: 4,
    },
  ];

  return (
    <div>
      <h3 className="font-semibold text-gray-800 text-xl">Strata Statistics</h3>
      <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-12">
        {stats.map((item, index) => (
          <div key={index} className={`border relative overflow-hidden rounded-lg bg-white px-5 py-5 shadow col-span-${item.columns}`} style={{ gridColumn: `span ${item.columns}` }}>
            <dt>
              <div className="absolute rounded-md bg-blue-700 p-3">
                <item.icon aria-hidden="true" className="h-6 w-6 text-white" />
              </div>
              <p className="ml-16 truncate text-sm font-medium text-gray-500">{item.name}</p>
            </dt>
            <dd className="ml-16 flex items-baseline">
              <p className="text-2xl font-semibold text-gray-900">{item.stat}</p>
            </dd>
          </div>
        ))}
      </dl>
    </div>
  );
}
